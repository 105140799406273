import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted, ref } from "vue";
import Apis_Agent from "@/apis/Apis_Agent";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Obj from "@/utils/Common/Common_Obj";
import Common_Str from "@/utils/Common/Common_Str";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'SelectLowAgent',
  emits: ['SelectAgent'],
  setup(__props, {
    emit: emits
  }) {
    const LangLib = new LangCom('Form', 'SelectLowAgent');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    let AgentList = [];
    let Columns = ref([]);
    let IsLoading = ref(true);
    onMounted(() => {
      Apis_Agent.getSubordinateAgent(2).then(res => {
        if (!Apis_Agent.checkResCode(res)) {
          return;
        }
        let agentArr = res.data || [];
        for (let agentArrKey in agentArr) {
          let agentName = agentArr[agentArrKey].agentUserName;
          AgentList.push({
            text: agentName,
            value: agentName
          });
        }
        Columns.value.length = 0;
        Columns.value = Common_Obj.DeepClone(AgentList);
        IsLoading.value = false;
      });
    });
    const showPicker = ref(false);
    const OpenPicker = () => {
      SearchKey.value = '';
      showPicker.value = IsLoading.value = true;
      Columns.value = Common_Obj.DeepClone(AgentList);
      IsLoading.value = false;
    };
    const NewAgentUserName = ref('');
    const onConfirm = ({
      selectedValues
    }) => {
      console.log('selectedValues', selectedValues, selectedValues.length);
      let agentUser = null;
      let errTips = false;
      if (selectedValues.length !== 1) {
        errTips = true;
      }
      if (errTips) {
        VantMsgLib.alert(GetLangSetup('onConfirm'));
      } else {
        agentUser = selectedValues[0];
        NewAgentUserName.value = agentUser;
        emits('SelectAgent', agentUser);
      }
      showPicker.value = false;
    };
    const SearchKey = ref('');
    const onSearch = () => {
      IsLoading.value = true;
      let arr = AgentList.filter(item => Common_Str.checkExistString(item.text, SearchKey.value));
      Columns.value = Common_Obj.DeepClone(arr);
      IsLoading.value = false;
      VantMsgLib.notify(2, GetLangSetup('onSearch') + `:${arr.length}`);
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_search = _resolveComponent("van-search");
      const _component_van_picker = _resolveComponent("van-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_field, {
        modelValue: NewAgentUserName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => NewAgentUserName.value = $event),
        "is-link": "",
        readonly: "",
        name: "picker",
        label: GetLangTemplate('NewAgentUserName.label'),
        placeholder: GetLangTemplate('NewAgentUserName.placeholder'),
        onClick: OpenPicker
      }, null, 8, ["modelValue", "label", "placeholder"]), _createVNode(_component_van_popup, {
        show: showPicker.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => showPicker.value = $event),
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_search, {
          modelValue: SearchKey.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => SearchKey.value = $event),
          placeholder: GetLangTemplate('SearchKey'),
          "show-action": "",
          onSearch: onSearch
        }, null, 8, ["modelValue", "placeholder"]), _createVNode(_component_van_picker, {
          loading: _unref(IsLoading),
          columns: _unref(Columns),
          onConfirm: onConfirm,
          onCancel: _cache[2] || (_cache[2] = $event => showPicker.value = false)
        }, null, 8, ["loading", "columns"])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};